import React from 'react'
import Maslows_Triangle from './img/Maslows_Triangle.svg'
import { Footer } from '../components/Footer'
import Helmet from 'react-helmet'

function Approach(props) {
  return (
    <div>

      {!props.tile && <Helmet>
        <title>Cuma Collective – Approach</title>
        <meta name="description" content="At CUMA, we help founders, entrepreneurs and enterprise design outcomes that serve human needs"/>
      </Helmet>}

      <div className="container">

        <div className="greyBlock frameBlock">
        <div className="pageName"><span>02</span>Approach</div>
        <h1>Investing in <br/>outcomes</h1>
        <div className="flexBox">
          <div className="blockRightSide redLine">
            <p>When we founded CUMA, we asked a very simple question: What if businesses were built on the principles of human behavior?</p>
            <p>Fundamentally, human behavior is based on a certain hierarchy of needs. Human needs. A construct rooted in behavioral psychology.</p>
            <p>In 1943, psychologist Abraham Maslow published a paper called the A Theory of Human Motivation. In it, he described five levels of human needs, from most basic to most fulfilling – or deficiency needs to growth needs. It has become commonly known as Maslow’s Hierarchy (Pyramid). And it has become one of the most central ideas in modern economics and sociology.</p>
            <img src={Maslows_Triangle} className="bigImg" alt="Maslows Triangle" />
            <p>For much of the past, Companies have produced goods and services and pushed them into the market. The origin of the offering was often based on what the company could do, or make, most efficiently. Operations drove innovation and distribution.</p>
            <p> Today, this model has become antiquated and broken. With the proliferation of exponential consumer options, next gen e-commerce and a shrinking global economy, we believe the companies that win will organize around demand space. Around human need.</p>
            <p>So, we start at a very different point. We start with the human need - or more specifically - the outcome that fulfills the human need. And we invest our time, talent and capital to make this happen.</p>
            <p>We look for and shape ideas that inspire human behavior. That is - we don’t build POV’s or recommendations. We build outcomes.</p>
            <p>What is an outcome? It is a need fulfilled.</p>
            <p>What is a need? The bedrock motivators and drivers of human behavior.</p>
            <p>At CUMA, we help founders, entrepreneurs and enterprise design outcomes that serve human needs. Because people don’t buy what you do or why you do it. They buy what it does for them.</p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
    </div>
  )
}

export default Approach;
