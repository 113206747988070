import React from 'react'
import $ from 'jquery'
import classNames from 'classnames'

import Home from './Home'
import Approach from './Approach'
import Expertise from './Expertise'
import Collective from './Collective'
import CollectivePreview from './CollectivePreview'
import Journal from './Journal'
import JournalPreview from './JournalPreview'
import Partnerships from './Partnerships'
import PartnershipsPreview from './PartnershipsPreview'

import { API_URLS } from '../consts'
import { Header } from '../components/Header'
import { Footer } from '../components/Footer'

const PAGE_HOME = 'home'
const PAGE_APPROACH = 'approach'
const PAGE_PARTNERSHIPS = 'partnerships'
const PAGE_EXPERTISE = 'expertise'
const PAGE_COLLECTIVE = 'collective'
const PAGE_JOURNAL = 'journal'

const ALL_PLATES = [
	PAGE_HOME,
	PAGE_APPROACH,
	PAGE_PARTNERSHIPS,
	PAGE_EXPERTISE,
	PAGE_COLLECTIVE,
	PAGE_JOURNAL
]

function renderGrid() {
	$("body")
		.removeClass("loaded home_page")
		.addClass("is-zoomed-out")
	$(".loader").remove()
	$(".book").addClass("book--zoom-out")
	$(".book__slider").attr("style", "")
	$('.page_item').removeClass('page--is-active')
	$(".book .view__page").css("display", "block")
}

function checkIfHomeScreenAnimationIsNeeded() {
	if (window.location.pathname !== '/') {
    return renderGrid() // not a home screen, so lets render grid immediately
	}

  $(".page_item").eq(0).addClass('page--is-active')
  $(".book__slider").css({
    "width": "1000%", "transform": "translateX(-" + 10 * $(".page--is-active").data("page") + "%)"
  })
  $(".book .view__page").css("display", "none")
  $(".page--is-active .view__page").css("display", "block")
  $(".loader").remove()
  $('<div class="loader"><div class="color"></div></div>').appendTo('body')
  $("body").removeClass("loaded")
  setTimeout(function () {
    $("body").addClass("loaded home_page")
  }, 0)

  setTimeout(() => renderGrid(), 1000)
}

class Menu extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
      selectedPlate: null,
			isPlateExpanded: false,
      showMenu: true, // we show menu immediately and hide it with 500ms delay to skip its content from SEO
			isHomeShown: false,
			isIos: navigator.userAgent.match(/(iPod|iPhone|iPad)/),
			posts: [],
			principles: [],
			collective: [],
			values: [],
		}

		this.hidePlateTimeout = null

		this.fetchData()
	}

	componentDidMount() {
		const { match: { params: { page } } } = this.props;
		const pageName = page || PAGE_HOME;

		$('.animation__container .view__page').removeClass('view__page--active');

		if (ALL_PLATES.includes(pageName)) {
			this.openPlate(pageName)
		}

		checkIfHomeScreenAnimationIsNeeded()
	}

	componentDidUpdate(prevProps) {
		const { match: { params: { page } } } = this.props;
		const pageName = page || PAGE_HOME;
		const prevPageName = prevProps.match.params.page || PAGE_HOME;

		if (pageName !== prevPageName) {
			if (ALL_PLATES.includes(pageName)) {
				this.openPlate(pageName)
			} else {
				this.closePlate()
			}
		}
	}

	handleHomeShow = () => {
		this.setState({ isHomeShown: true })
	}

	updateRoute = (pageName) => {
		setTimeout(() => this.props.history.push('/' + pageName), 0)
	}

	handleClick(e) {
		const pageId = e.target.parentElement.getAttribute("id");
		this.updateRoute(pageId)
	}

	openPlate = (plate) => {
		clearTimeout(this.hidePlateTimeout)
		clearTimeout(this.expandCompleteTimeout)

		const { x, y, height, width } = document.querySelector(`#${plate}`).getBoundingClientRect()
		const transformX = x + (width / 2)
		const transformY = y + (height / 2)

		this.setState({ selectedPlate: plate, isPlateExpanded: true }, () => {
			$('body')
        .removeClass('has-no-tile-expanded')
        .addClass('is-tile-expanded')
			$('.animation__container .view__page')
				.css({ 'transform-origin': `${transformX}px ${transformY}px` })

			setTimeout(() => {
				$('.animation__container .view__page').addClass('view__page--active');
				$('.animation__inner').scrollTop(0)
			}, 0);
		});

		this.expandCompleteTimeout = setTimeout(() => {
			$('body').addClass('is-tile-expanded--complete')
      this.setState({
        showMenu: false // hide menu in 500ms, after opening the currently active plate
      })
    }, 500)
	};

	closePlate = () => {
    this.setState({
      showMenu: true // show menu immediately, before closing the currently active plate
    }, () => {
      checkIfHomeScreenAnimationIsNeeded() // this is required to handle appearance for home screen when you open it directly
      this.hidePlateTimeout = setTimeout(() => {
        this.setState({
          selectedPlate: null, isPlateExpanded: false
        });
      }, 500)

      $('.animation__container .view__page').removeClass('view__page--active');
      $('body').removeClass('is-tile-expanded')
        .removeClass('is-tile-expanded--complete')
        .addClass('has-no-tile-expanded')
    })
	}

	handleMenuClick = () => {
		const { history } = this.props;
		if (window.location.pathname.indexOf('/menu') === -1) {
      history.push('/menu');
		} else {
			history.goBack()
		}
	};

	fetchData = async () => {
		try {
			const postsPromise = $.getJSON(API_URLS.POSTS)
			const principlesPromise = $.getJSON(API_URLS.PRINCIPLES)
			const collectivePromise = await $.getJSON(API_URLS.COLLECTIVE)
			const valuesPromise = await $.getJSON(API_URLS.VALUES)

			const [posts, principles, collective, values] = await Promise.all([
				postsPromise,
				principlesPromise,
				collectivePromise,
				valuesPromise
			])
			this.setState({ posts, principles, collective, values })
		} catch (err) {
			console.error('Error fetching posts...', err)
		}
	}

	render() {
		const {
			isPlateExpanded,
			selectedPlate,
			isHomeShown,
			isIos,
			posts,
			principles,
			collective,
			values,
		} = this.state;
		const { location } = this.props;
		const page_mask = location.pathname === '/menu' ? <div className="page__mask" onClick={this.handleClick.bind(this)} /> : null;
		return (
		  <div className={classNames("menuPage", isIos && 'menuPage--isIos')}>
				<Header onMenuClick={this.handleMenuClick} />

        <div className={isPlateExpanded
          ? "animation__container animation__container--active"
          : "animation__container animation__container--hidden"}>
          <div className="animation__inner page_item">
            <div className="view__page">
              {selectedPlate === 'home' && <Home
                isInteractive
                disableAnimation={isHomeShown}
                onShow={this.handleHomeShow}
                principles={principles}
              />}
              {selectedPlate === 'approach' && <Approach />}
              {selectedPlate === 'partnerships' && <Partnerships />}
              {selectedPlate === 'expertise' && <Expertise values={values} />}
              {selectedPlate === 'collective' && <Collective collective={collective} />}
              {selectedPlate === 'journal' && !!posts.length && <Journal posts={posts} />}
            </div>
          </div>
        </div>

        {this.state.showMenu && <div className="book">
          <div className="book__slider">
            <div id="home" className="page_item" data-page="0">
							<div className="view__page">
								<div className="page-item_title">
									<div
										className="page_title"
										onClick={() => this.updateRoute('home')}
									><div className="page_title-inner-container"><span>01</span>Home</div></div>

								</div>
								<div className="view__page-container">
									<Home disableAnimation principles={principles} tile />
								</div>
							</div>
							{page_mask}
						</div>
						<div id="approach" className="page_item" data-page="1">
							<div className="view__page">
								<div className="page-item_title">
									<div
										className="page_title"
										onClick={() => this.updateRoute('approach')}
									>
										<div className="page_title-inner-container"><span>02</span>Approach</div>
									</div>
								</div>
								<div className="view__page-container">
									<Approach tile />
								</div>
							</div>
							{page_mask}
						</div>
						<div id="partnerships" className="page_item" data-page="2">
							<div className="view__page">
								<div className="page-item_title">
									<div
										className="page_title"
										onClick={() => this.updateRoute('partnerships')}
									>
										<div className="page_title-inner-container"><span>03</span>Partnerships</div>
									</div>
								</div>
								<div className="view__page-container">
									<PartnershipsPreview tile />
								</div>
							</div>
							{page_mask}
						</div>
						<div id="expertise" className="page_item" data-page="3">
							<div className="view__page">
								<div className="page-item_title">
									<div
										className="page_title"
										onClick={() => this.updateRoute('expertise')}
									>
										<div className="page_title-inner-container"><span>04</span>Expertise</div>
									</div>
								</div>
								<div className="view__page-container">
									<Expertise values={values} tile />
								</div>
							</div>
							{page_mask}
						</div>
						<div id="collective" className="page_item" data-page="4">
							<div className="view__page">
								<div className="page-item_title">
									<div
										className="page_title"
										onClick={() => this.updateRoute('collective')}
									>
										<div className="page_title-inner-container"><span>05</span>Collective</div>
									</div>
								</div>
								<div className="view__page-container">
									<CollectivePreview nonInteractive collective={collective} />
								</div>
							</div>
							{page_mask}
						</div>
						<div id="journal" className="page_item" data-page="5">
							<div className="view__page">
								<div className="page-item_title">
									<div
										className="page_title"
										onClick={() => this.updateRoute('journal')}
									>
										<div className="page_title-inner-container"><span>06</span>Journal</div>
									</div>
								</div>
								<div className="view__page-container">
									{posts.length && <JournalPreview nonInteractive posts={posts} />}
								</div></div>
							{page_mask}
						</div>
					</div>
				</div>}

				<div className="preview-footer"><Footer /></div>

			</div>
		)
	}
}

export default Menu
