import React from 'react'
import $ from 'jquery'
import { Animated } from 'react-animated-css'
import classNames from 'classnames'
import Helmet from 'react-helmet'

import { API_BASE_URL } from '../consts'
import { Footer } from '../components/Footer'


class Home extends React.Component {

  constructor(props) {
    super(props)

    this.delay = false;
    this.timerId = null;
    this.index = 0;
    this.yDown = null;
    this.showFooter = false;
  }

  componentDidMount() {
    this.preloadImages(this.props.principles);

    if (!this.props.isInteractive) return;

    setTimeout(() => {
      $('.homePage--interactive section.blackBlock').addClass('active');
      const el = document.querySelector(".homePage--interactive section.blackBlock.active");
      el.addEventListener('wheel', this.toSecond, { passive: false });
      el.addEventListener('mousewheel', this.toSecond, { passive: false });
      el.addEventListener('DOMMouseScroll', this.toSecond, { passive: false });
      el.addEventListener("touchmove", this.toSecond, { passive: false });
    }, 1000);

    document.addEventListener('touchstart', this.handleTouchStart, false)
  }

  componentDidUpdate(prevProps) {
    if (this.props.principles !== prevProps.principles) {
      this.preloadImages(this.props.principles)
    }
  }

  componentWillUnmount() {
    if (this.props.onShow) this.props.onShow();
    document.removeEventListener('touchstart', this.handleTouchStart)
  }

  preloadImages = async (principles) => {
    const imgLoadingPromises = principles.reduce((acc, principle) => {
      return [
        ...acc,
        new Promise((resolve, reject) => {
          const img = new Image();
          img.src = `${API_BASE_URL}${principle.img.url}`;
          img.onload = () => resolve();
          img.onerror = (err) => reject(err)
        })
      ]
    }, []);

    try {
      await Promise.all(imgLoadingPromises)
    } catch (err) {
      // nothing we can do here
    }
  };

  toSecond = (e) => {
    e.preventDefault();
    if (this.delay) return;
    this.delay = true;

    setTimeout(() => {
      this.delay = false
    }, 1500);

    if (e.deltaY > 0 || e.type === "touchmove") {
      console.log('toSecond::sectionWrap DOWN');
      this.slideDownToSecond();
    }
  };

  slideDownToSecond = () => {

    console.log('slideDownToSecond');

    $('.homePage--interactive .sectionWrap')
      .addClass('animated')
      .css({ 'transform': 'translate(0px,-100%)' });

    $('.homePage--interactive section').removeClass('active');
    $('.homePage--interactive section.greyBlock').addClass('active');
    $('.homePage--interactive section.greyBlock h1').addClass('animated fadeInUp').css({
      'animation-delay': '500ms',
      'animation-duration': '500ms',
      'pointer-events': 'all'
    });
    $('.homePage--interactive section.greyBlock .blockRightSide')
      .addClass('animated fadeInUp')
      .css({
        'animation-delay': '500ms',
        'animation-duration': '500ms',
        'pointer-events': 'all'
      });

    setTimeout(() => {
      $('.homePage--interactive .sectionWrap')
        .removeClass('animated')
        .css({ 'transform': 'translate(0px,0px)' });

      $('.homePage--interactive section.blackBlock').css('top', '-100%');
      $('.homePage--interactive section.greyBlock').css('top', '0');
      const el = document.querySelector(".homePage--interactive section.greyBlock");
      el.addEventListener('wheel', this.toThird, { passive: false });
      el.addEventListener('mousewheel', this.toThird, { passive: false });
      el.addEventListener('DOMMouseScroll', this.toThird, { passive: false });
      el.addEventListener("touchmove", this.handleTouchUp, { passive: false });
    }, 500);
  };

  slideUpToSecond = () => {

    console.log('slideUpToSecond');

    $('.homePage--interactive .sectionWrap')
      .addClass('animated')
      .css({ 'transform': 'translate(0px, 100%)' });

    setTimeout(() => {
      $('.homePage--interactive .sectionWrap')
        .removeClass('animated')
        .css({ 'transform': 'translate(0px,0px)' });

      $('.homePage--interactive section.greyBlock').css('top', '0');
      $('.homePage--interactive section.principles').css('top', '100%');

      const el = document.querySelector(".homePage--interactive section.greyBlock");
      el.addEventListener('wheel', this.toThird, { passive: false });
      el.addEventListener('mousewheel', this.toThird, { passive: false });
      el.addEventListener('DOMMouseScroll', this.toThird, { passive: false });
      el.addEventListener("touchmove", this.handleTouchUp, { passive: false });
    }, 500);

  };

  toThird = (e) => {
    e.preventDefault();

    if (this.delay) return;
    this.delay = true;
    console.log('toThird::action');

    setTimeout(() => {
      this.delay = false
    }, 1000);

    clearTimeout(this.timerId);

    this.timerId = setTimeout(() => {

      if (e.deltaY < 0) {

        console.log('toThird::action UP');

        $('.homePage--interactive .sectionWrap')
          .addClass('animated')
          .css({ 'transform': 'translate(0px,100%)' });

        $('.homePage--interactive section').removeClass('active');
        $('.homePage--interactive section.blackBlock').addClass('active');
        $('.homePage--interactive section.principles').css('top', '200%');
        const el = document.querySelector(".homePage--interactive section.greyBlock");
        el.addEventListener('wheel', this.toThird, { passive: false });
        el.addEventListener('mousewheel', this.toThird, { passive: false });
        el.addEventListener('DOMMouseScroll', this.toThird, { passive: false });
        el.addEventListener("touchmove", this.handleTouchUp, { passive: false });
        setTimeout(() => {
          $('.homePage--interactive .sectionWrap')
            .removeClass('animated')
            .css({ 'transform': 'translate(0px,0px)' });
          $('.homePage--interactive section.blackBlock').css('top', '0');
          $('.homePage--interactive section.greyBlock').css('top', '100%');
        }, 500);

      } else if (e.deltaY > 0) {

        console.log('toThird::action DOWN');

        $('.homePage--interactive .sectionWrap')
          .addClass('animated')
          .css({ 'transform': 'translate(0px,-100%)' });
        $('.homePage--interactive section').removeClass('active');
        $('.homePage--interactive section.principles')
          .addClass('active')
          .css('top', '100%');

        const el = document.querySelector(".homePage--interactive .principles");
        el.addEventListener('wheel', this.fn, { passive: false });
        el.addEventListener('mousewheel', this.fn, { passive: false });
        el.addEventListener('DOMMouseScroll', this.fn, { passive: false });
        el.addEventListener("touchmove", this.handleTouchMove, { passive: false });

        setTimeout(() => {
          $('.homePage--interactive .sectionWrap')
            .removeClass('animated')
            .css({ 'transform': 'translate(0px,0px)' });
          $('.homePage--interactive section.greyBlock').css('top', '-100%');
          $('.homePage--interactive section.principles').css('top', '0');
        }, 700);

      }
    }, 0)
  };

  getTouches = (e) => {
    return e.touches ||             // browser API
      e.originalEvent.touches; // jQuery
  };

  handleTouchStart = (e) => {
    const firstTouch = this.getTouches(e)[0];
    this.yDown = firstTouch.clientY;
  };

  slideUp = () => {
    console.log('slideUp');
    const { principles } = this.props;
    if (this.index === 0) {
      return this.slideUpToSecond();
    } else {
      if (this.index === principles.length - 1 && this.showFooter) {
        return this.slideUpFromFooter();
      } else {
        this.index--;
      }
    }

    if (this.removeCallback) {
      clearTimeout(this.removeTimeout);
      this.removeCallback()
    }

    $('.homePage--interactive .rightSide, .homePage--interactive .leftSide').addClass('animated');
    $('.homePage--interactive .rightSide').css({ 'transform': 'translate(0px,100%)' });
    $('.homePage--interactive .leftSide').css({ 'transform': 'translate(0px,-100%)' });
    $('.homePage--interactive .rightSide').append('<div class="slide slide-' + principles[this.index].id + '"><div class="sliderBackground" style="background-color:' +
      principles[this.index].color + '"></div><div class="slideOrder">' + principles[this.index].id + '<div style="color:' +
      principles[this.index].color + '">08</div></div><h2 class="slideTitle">' + principles[this.index].title +
      '</h2><p class="slideDesc">' + principles[this.index].desc + '</p></div>');
    $('.homePage--interactive .leftSide').append(
      `<div class="slide slide-' + ${principles[this.index].id}" style="background-image: url(${API_BASE_URL}${principles[this.index].img.url})"></div>`
    );
    $('.homePage--interactive .rightSide').find('.slide').eq(1).css('top', '-100%');
    $('.homePage--interactive .leftSide').find('.slide').eq(1).css('top', '100%');
    $('.homePage--interactive .rightSide, .homePage--interactive .leftSide').find('.slide').eq(0).css('top', '0');

    this.removeCallback = () => {
      $('.homePage--interactive .rightSide, .homePage--interactive .leftSide').removeClass('animated');
      $('.homePage--interactive .rightSide, .homePage--interactive .leftSide').css({ 'transform': 'translate(0px, 0px)' });
      $('.homePage--interactive .rightSide, .homePage--interactive .leftSide').find('.slide:first').remove();
      $('.homePage--interactive .rightSide .slide:first, .homePage--interactive .leftSide .slide:first').css('top', '0');
    }

    this.removeTimeout = setTimeout(() => {
      if (this.removeCallback) this.removeCallback()

      this.removeCallback = null
      this.removeTimeout = null
    }, 500)
  };

  slideDown = () => {
    console.log('slideDown');
    const { principles } = this.props;

    if (this.index === principles.length - 1) {
      console.log('slideDown - no more slides');
      this.slideDownToFooter();
      return;
    } else {
      this.index++;
    }

    if (this.removeCallback) {
      clearTimeout(this.removeTimeout)
      this.removeCallback()
    }

    $('.homePage--interactive .rightSide, .homePage--interactive .leftSide').addClass('animated');
    $('.homePage--interactive .rightSide').css({ 'transform': 'translate(0px, -100%)' });
    $('.homePage--interactive .leftSide').css({ 'transform': 'translate(0px, 100%)' });
    $('.homePage--interactive .rightSide').append(
      '<div class="slide slide-' + principles[this.index].id + '"><div class="sliderBackground" style="background-color:' +
      principles[this.index].color + '"></div><div class="slideOrder">' + principles[this.index].id + '<div style="color:' +
      principles[this.index].color + '">08</div></div><h2 class="slideTitle">' + principles[this.index].title +
      '</h2><p class="slideDesc">' + principles[this.index].desc + '</p></div>'
    );
    $('.homePage--interactive .leftSide').append(
      `<div class="slide slide-${principles[this.index].id}" style="background-image: url(${API_BASE_URL}${principles[this.index].img.url})"></div>`
    );
    $('.homePage--interactive .rightSide, .homePage--interactive .leftSide').find('.slide').eq(0).css('top', '0');
    $('.homePage--interactive .rightSide').find('.slide').eq(1).css('top', '100%');
    $('.homePage--interactive .leftSide').find('.slide').eq(1).css('top', '-100%');

    this.removeCallback = () => {
      $('.homePage--interactive .rightSide, .homePage--interactive .leftSide')
        .removeClass('animated')
        .css({ 'transform': 'translate(0px, 0px)' })
        .find('.slide:first').remove();
      $('.homePage--interactive .rightSide .slide:first, .homePage--interactive .leftSide .slide:first').css('top', '0');
    }

    this.removeTimeout = setTimeout(() => {
      if (this.removeCallback) this.removeCallback()

      this.removeCallback = null
      this.removeTimeout = null
    }, 500)
  };

  handleTouchMove = (e) => {
    e.preventDefault();
    if (!this.yDown) {
      return;
    }
    const yUp = e.touches[0].clientY;
    const yDiff = this.yDown - yUp;

    if (yDiff > 0) {
      this.slideDown()
    } else {
      this.slideUp()
    }

    this.yDown = null;
  };

  fn = (e) => {
    e.preventDefault();

    if (this.delay) return;
    this.delay = true;
    setTimeout(() => {
      this.delay = false
    }, 1000);

    if (e.deltaY < 0) {
      this.slideUp()

    } else if (e.deltaY > 0) {
      this.slideDown()
    }
  };

  handleTouchUp = (e) => {
    console.log('handleTouchUp');
    e.preventDefault();
    if (!this.yDown) {
      return;
    }

    var yUp = e.touches[0].clientY;
    var yDiff = this.yDown - yUp;

    clearTimeout(this.timerId);
    this.timerId = setTimeout(() => {

      if (yDiff > 0) { /* to down*/

        $('.homePage--interactive .sectionWrap')
          .addClass('animated')
          .css({ 'transform': 'translate(0px,-100%)' });
        $('.homePage--interactive section').removeClass('active');
        $('.homePage--interactive section.principles')
          .addClass('active')
          .css('top', '100%');

        const el = document.querySelector(".homePage--interactive .principles");
        el.addEventListener('wheel', this.fn, { passive: false });
        el.addEventListener('mousewheel', this.fn, { passive: false });
        el.addEventListener('DOMMouseScroll', this.fn, { passive: false });
        el.addEventListener("touchmove", this.handleTouchMove, { passive: false });

        setTimeout(() => {
          $('.homePage--interactive .sectionWrap')
            .removeClass('animated')
            .css({ 'transform': 'translate(0px,0px)' });
          $('.homePage--interactive section.greyBlock').css('top', '-100%');
          $('.homePage--interactive section.principles').css('top', '0');
        }, 500);

      } else { 	/*to up*/

        $('.homePage--interactive .sectionWrap')
          .addClass('animated')
          .css({ 'transform': 'translate(0px,100%)' });
        $('.homePage--interactive section').removeClass('active');
        $('.homePage--interactive section.blackBlock').addClass('active');

        const el = document.querySelector(".homePage--interactive section.greyBlock");
        el.addEventListener('wheel', this.toThird, { passive: false });
        el.addEventListener('mousewheel', this.toThird, { passive: false });
        el.addEventListener('DOMMouseScroll', this.toThird, { passive: false });
        el.addEventListener("touchmove", this.handleTouchUp, { passive: false });

        setTimeout(() => {
          $('.homePage--interactive .sectionWrap')
            .removeClass('animated')
            .css({ 'transform': 'translate(0px,0px)' });

          $('.homePage--interactive section.blackBlock').css('top', '0');
          $('.homePage--interactive section.greyBlock').css('top', '100%');
        }, 500);

      }
    }, 0);

    this.yDown = null;
  };

  slideDownToFooter = () => {
    if (this.showFooter) {
      return;
    }
    console.log('slideDownToFooter');

    const height = $('.homePage--interactive .homepage--footer')
      .addClass('active')
      .height();

    $('.homePage--interactive section.principles')
      .removeClass('active')
      .animate({
        'top': '-' + height + 'px' // TODO: this is not responsive, we should also handle a window resize
      }, 300);

    const el = document.querySelector(".homePage--interactive .homepage--footer");
    el.addEventListener('wheel', this.fn, { passive: false });
    el.addEventListener('mousewheel', this.fn, { passive: false });
    el.addEventListener('DOMMouseScroll', this.fn, { passive: false });
    el.addEventListener("touchmove", this.handleTouchMove, { passive: false });

    this.showFooter = true;
  };

  slideUpFromFooter = () => {
    if (!this.showFooter) {
      return;
    }

    $('.homePage--interactive .homepage--footer').removeClass('active');
    $('.homePage--interactive section.principles')
      .addClass('active')
      .animate({
        'top': '0'
      }, 300);

    const el = document.querySelector(".homePage--interactive .homepage--footer");
    el.removeEventListener('wheel', this.fn);
    el.removeEventListener('mousewheel', this.fn);
    el.removeEventListener('DOMMouseScroll', this.fn);
    el.removeEventListener("touchmove", this.handleTouchMove);

    this.showFooter = false;
  };

  render() {
    const { isInteractive, disableAnimation, principles } = this.props;

    return (
      <div className={classNames(
        'homePage container',
        isInteractive && 'homePage--interactive',
        disableAnimation && 'homePage--animated-once'
        )}>

        {!this.props.tile && <Helmet>
          <title>Cuma Collective – Founded by Michael D’Antonio, we grow customer adoption.</title>
          <meta name="description" content="Founded by Michael D’Antonio, we are a strategic investment and advisory firm dedicated to helping founders, entrepreneurs and enterprise turn their insights into consumer outcomes."/>
        </Helmet>}

        <div className="sectionWrap animated">

          <section className="blackBlock flexBox">

            <div className="sectionContent">
              <Animated animationIn="fadeInUp" isVisible={true} animationInDelay={500} animationInDuration={1000}>
                <h4>People don’t buy what you do.</h4>
                <h4>How you do it.</h4>
                <h4>Or Why you do it.</h4>
                <h4>They buy what it does for them.</h4>
              </Animated>

              <Animated animationIn="fadeInUp" isVisible={true} animationInDelay={1000} animationInDuration={1000}>
                <div className="typeWrapper">
                  <h2 className="blueFont animated animatedFadeInUp fadeInUp">
                    <span>We </span>
                    <span>grow </span>
                    <span>customer adoption.</span>
                  </h2>
                </div>
              </Animated>

              <div className="mouse_wrap">
                <div className="mouse">
                  <div className="roll" />
                  <div className="rollshadow" />
                </div>
                <div className="rolltext">Scroll</div>
              </div>

            </div>
          </section>

          <section className="greyBlock flexBox">
            <div className="sectionContent">
              <h1>We’re  <br />CUMA</h1>
              <div className="flexBox">
                <div className="blockRightSide redLine">
                  <p>Founded by Michael D’Antonio, we are a strategic investment and advisory firm dedicated to helping founders, entrepreneurs
                      and enterprise turn their insights into consumer outcomes.</p>
                </div>
              </div>
              <div className="mouse_wrap">
                <div className="mouse">
                  <div className="roll" />
                  <div className="rollshadow" />
                </div>
                <div className="rolltext">Scroll</div>
              </div>
            </div>
          </section>

          {!!principles.length && (
            <section className="principles">
              <div className="slider flexBox">
                <div className="leftSide">
                  <div
                    className={`slide slide-${principles[0].id}`}
                    style={{
                      backgroundImage: `url(${API_BASE_URL}${principles[0].img.url})`
                    }}
                  />
                </div>
                <div className="rightSide">
                  <div className={`slide slide-${principles[0].id}`}>
                    <div className="sliderBackground" style={{ backgroundColor: `${principles[0].color}` }} />
                    <div className="slideOrder">{principles[0].id} <div style={{ color: `${principles[0].color}` }}>08</div></div>
                    <h2 className="slideTitle">{principles[0].title}</h2>
                    <p className="slideDesc">{principles[0].desc}</p>
                  </div>
                </div>
              </div>
            </section>
          )}

          <div className="homepage--footer">
            <Footer />
          </div>

        </div>

      </div>
    )
  }
}

export default Home;
