import React, { PureComponent } from 'react'
import $ from 'jquery'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'

import { API_BASE_URL } from '../consts'
import Article from './Article'
import { Footer } from '../components/Footer'


class JournalPreview extends PureComponent {

  constructor(props) {
    super(props)

    this.state = {
      posts: props.posts,
      currentSlug: null,
      currentId: null,
      currentIndex: 0,
      isArticleExpanded: false,
      isArticleExpandedCompleted: false
    }

    this.carouselInterval = null
    this.expandingTimeout = null
  }

  componentDidMount() {
    const slug = this.props.match.params.slug
    if (slug) {
      const foundPost = this.state.posts.find(post => post.slug === slug)
      const id = foundPost ? foundPost.id : this.state.posts[0].id
      const index = foundPost ? foundPost.id - 1 : this.state.posts[0].id - 1

      this.setState({
        currentSlug: slug,
        currentId: +id,
        currentIndex: +index,
        isArticleExpanded: true,
        isArticleExpandedCompleted: true
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {

    const { posts } = this.state;
    if (!posts.length || this.props.nonInteractive) return

    const slug = this.props.match.params.slug
    const prevSlug = prevProps.match.params.slug
    if (slug === prevSlug && posts === prevState.posts) return

    $('.animation__inner').scrollTop(0)
    clearTimeout(this.expandingTimeout)

    let index = posts[0].id - 1
    let id = posts[0].id
    if (slug) {
      const foundPost = posts.find(post => post.slug === slug)
      index = foundPost ? foundPost.id - 1 : index
      id = foundPost ? foundPost.id : id
    }

    const isAnimatingSubpage = $('body').hasClass('innerPage--loading-enter');
    if (isAnimatingSubpage && id) {
      this.setState({ currentSlug: slug, currentId: id, currentIndex: index });
      $('body').addClass('innerPage--loading-active');
      setTimeout(() => {
        $('body')
          .removeClass('innerPage--loading-enter')
          .addClass('innerPage--loading-exit');

        setTimeout(() => {
          $('body')
            .removeClass('innerPage--loading-active')
            .removeClass('innerPage--loading-exit');
        }, 500);
      }, 500)
    } else {
      if (slug) {
        this.setState({
          currentSlug: slug, currentId: id, currentIndex: index, isArticleExpanded: true
        });
        this.expandingTimeout = setTimeout(() => {
          this.setState({
            isArticleExpandedCompleted: true
          })
        }, 500)
      } else {
        this.setState({
          isArticleExpanded: false,
          isArticleExpandedCompleted: false
        });
        setTimeout(() => {
          this.setState({
            currentSlug: null,
            currentId: null,
            currentIndex: index
          });
        }, 1000);
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.carouselInterval)
  }

  mouseEnter = (event) => {
    const index = event.target.getAttribute('data-key') || 0;
    this.setState({ currentIndex: +index })
  }

  handlePostItemClick = (e, post) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.history.push(`/journal/${post.slug}`)
  }

  render() {

    const {
      posts,
      currentIndex,
      isArticleExpanded,
      isArticleExpandedCompleted,
    } = this.state;

    const { history, nonInteractive } = this.props;

    if (!posts.length) return null;
    const journalPosts = nonInteractive ? [posts[0]] : posts;

    return (
      <>
        <div className={classNames(
          "container journalPage",
          isArticleExpanded && 'journalPage--article-expanded',
          isArticleExpandedCompleted && 'journalPage--article-expanded-completed'
        )}>
          <div className="posts greyBlock fullScreenBlock flexBox">
            <div className="leftSide">
              {journalPosts.map((post, index) => (
                <div
                  className={
                    classNames("postImage fixHeight", index === currentIndex && 'visible')
                  }
                  key={post.id}
                  data-key={post.id}>
                  {post.img
                    ? <div className="postImageInner" style={{ backgroundImage: `url(${API_BASE_URL}${post.img.url})` }} />
                    : null}
                </div>
              ))}
            </div>
            <div className="rightSide greyBlock fixHeight">
              <div className="rightSideInner">
                <div className="pageName"><span>06</span>Journal</div>
                <h1>Journal</h1>
                <div className="postsList">
                  {posts.map((post, index) => (
                    <div className="postItem" key={post.id} onClick={e => this.handlePostItemClick(e, post)}>
                      <a href={`/journal/${post.slug}`} className="postTitle" data-key={index} onMouseEnter={this.mouseEnter}>{post.title}</a>
                      <div className="postInfo">{post.category} • {post.author}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="journalPage--article-background" />
          </div>

          {this.state.currentSlug && posts.length && <Article
            slug={this.state.currentSlug}
            onPageChange={slug => {
              $('body').addClass('innerPage--loading-enter');

              setTimeout(() => {
                $('.animation__inner').scrollTop(0);
                history.push(`/journal/${slug}`);
                this.setState({
                  isArticleExpanded: true,
                  isArticleExpandedCompleted: true
                });
              }, 1000);

            }}
            posts={posts}
          />}

          <div className="innerPageBackgroundLoader" />

          <Footer />
        </div>
      </>
    )
  }
}

export default withRouter(JournalPreview)
