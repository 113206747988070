import React from 'react'
import { FaArrowLeft } from 'react-icons/fa'
import { history } from '../App'

import Partners from './data/partners.json' // TODO: move the data to Strapi CMS


function Partner({ match }) {
	const partner = Partners.find(p => p.id === match.params.id);
	return (
		<div className="partnerPage">
			<div className="backBtn blackStyle" onClick={() => history.goBack()}><FaArrowLeft /></div>
			{
				partner.img !== ''
					? <div className="articleImageInner" style={{ backgroundImage: `url(${partner.img})` }}/>
					: null
			}
			<div className="flexBox greyBlock frameBlock">
				<div className="partnerLeftCol">
					<h1>{partner.name}</h1>
					<div>{partner.subheaders.title}
						{partner.subheaders.map((subheaders) => (
							<div className="subGroup" key={subheaders.number}>
								<div className="blueTitle" dangerouslySetInnerHTML={{ __html: subheaders.title }}/>
								<div className="subGroupDesc" dangerouslySetInnerHTML={{ __html: subheaders.desc }}/>
							</div>
						))}
					</div>
				</div>
				<div className="partnerRightCol">
					<div className="partnerAboutText" dangerouslySetInnerHTML={{ __html: partner.about }}/>
				</div>
			</div>
		</div>
	)
}

export default Partner
