import React from 'react'
import $ from 'jquery'
import { withRouter } from 'react-router-dom'

import { equal } from '../utils'
import { API_BASE_URL } from '../consts'
import { history } from '../App'

import Bio from './Bio'
import { Footer } from '../components/Footer'


class CollectivePreview extends React.Component {
  constructor() {
    super();

    this.state = {
      currentId: null,
    };
    this.expandingTimeout = null;
  }

  componentDidMount() {
    const id = this.props.match.params.id;
    if (id) {
      this.setState({
        currentId: id
      })
    }
  }

  componentDidUpdate(prevProps) {
    const id = this.props.match.params.id;
    if (id === prevProps.match.params.id) return;

    $('.animation__inner').scrollTop(0);
    clearTimeout(this.expandingTimeout);

    $('body').addClass('innerPage--loading-active');
    setTimeout(() => {
      $('body')
        .removeClass('innerPage--loading-enter')
        .addClass('innerPage--loading-exit');
      this.setState({ currentId: id });

      this.expandingTimeout = setTimeout(() => {
        $('body')
          .removeClass('innerPage--loading-active')
          .removeClass('innerPage--loading-exit');
      }, 500);
    }, 0);
  }

  navigateTo = (id) => {
    $('body').addClass('innerPage--loading-enter');
    setTimeout(() => {
      const uri = '/collective' + (id ? '/' + id : '');
      history.push(uri);
    }, 1000);
  };

  render() {
    const { currentId, nonInteractive } = this.state;
    const { collective } = this.props;

    if (collective && collective.length === 0) return null; // data is still loading

    let bio = null;
    if (currentId) {
      const bioRecord = collective.find(p => equal(p.slug, currentId));
      bio = currentId
        ? <Bio data={bioRecord} onChange={this.navigateTo} collective={collective} />
        : null
    }

    return (
      <div className={"container collectivePage"}>
        <div className="loader__container" />

        {bio ? bio : <>

          <div className="blueBlock frameBlock collectivePage__intro">
            <div className="pageName"><span>05</span>Collective</div>
            <h1>The CUMA <br /> Collective</h1>
            <div className="flexBox">
              <div className="blockRightSide redLine">
                <p>We’re a collective of investors, advisors and consumers bound by a common denominator: to help
                achieve better outcomes. We come from diverse backgrounds, professions and industry. We are financiers,
                marketers, engineers, technologists, consultants, doctors, healthcare professionals, lawyers,
                real estate developers, retailers, restaurateurs, writers, designers, artists, salespersons, shoppers,
                enthusiasts and various entrepreneurs. We have many passions and interests. We look to exploit areas
                that don’t exist or occupy spaces nobody sees. We are paranoid. We embrace the uncomfortable.
                And we have learned most, from our mistakes.</p>
              </div>
            </div>
          </div>

          <div className="greyBlock smallerFrameBlock">
            <div className="flexBox ourCollective">

              {!nonInteractive && collective.map((bio) => (
                <div className="bio redLineAfter" key={bio.id}>
                  <div className="link_to_bio" onClick={() => this.navigateTo(bio.slug)}>
                    <div className="bioPhoto">
                      <img className="bio-img"
                        src={bio.img ? `${API_BASE_URL}${bio.img.url}` : "/img/collective/no-photo.png"}
                        alt={bio.name} />
                    </div>
                    <div className="bioName">{bio.name}</div>
                  </div>
                </div>
              ))}

            </div>

          </div>

        </>}

        <div className="innerPageBackgroundLoader" />

        <Footer />
      </div>
    )
  }

}

export default withRouter(CollectivePreview);
