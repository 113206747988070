import React from 'react'
import './App.css';
import { Router, BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga';
import { GOOGLE_ANALYTICS_TRACKING_ID } from './consts'

import Routes from './Routes';
import * as serviceWorker from './serviceWorker';

export const history = createBrowserHistory();
ReactGA.initialize(GOOGLE_ANALYTICS_TRACKING_ID);

history.listen((location) => {
  window.ga('set', 'page', location.pathname + location.search);
  window.ga('send', 'pageview');
});

function App() {
  return (
    <BrowserRouter>
      <Router history={history}>
        <Routes />
      </Router>
    </BrowserRouter>
  )
}

export default App;

serviceWorker.unregister();
