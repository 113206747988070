import React from 'react'
import { Route, Switch } from 'react-router-dom'

import NotFound from './pages/NotFound'
import Menu from './pages/Menu'

const Routes = () => {
  return (
    <Switch>
      <Route path="/:page?" component={Menu} />
      <Route path="/menu" component={Menu} />
      <Route component={NotFound} />
    </Switch>
  )
};

export default Routes
