import React from 'react';
import {Footer} from "../components/Footer";
// import Partners from "./data/partners.json"


const PartnershipsPreview = () => (
  <div className="container">
    <div className="greyBlock frameBlock">
      <div className="pageName"><span>03</span>Partnerships</div>
      <h1>
        We succeed<br />
        together
      </h1>
      <div className="flexBox blockGroup">
        <div className="blockRightSide redLine">
          <p>We aren’t constricted by a set stage, structure or equity position. We don’t set standard hourly rates or project fees. And we don’t charge commissions. We invest in people, businesses and ideas. We look for collaboration that challenges all of us and partnerships that make sense.</p>
          <p>Above all, we value the entrepreneurial spirit – the idea that anyone, with enough commitment and determination, along with the right help, can build a successful enterprise.</p>
          <p>We are a hands-on investment group. We don’t engage in deals if we don’t add value. We don’t specialize in just one area because we believe it stunts innovation and growth. We leverage our various subject matter expertise, experience and perspectives as a multi-disciplinary method to adapt and apply thought-leadership, across many sectors.</p>
          <p>We provide intellectual, human, social and financial capital because every opportunity and situation are different. Developed with each unique partner, our deals are some combination of capital structured so we grow when you grow. We prosper when you prosper. And we don’t, when you don’t.</p>
        </div>
      </div>

      {/* TODO: commenting this out as there are no client so far
       <div className="flexBox blockGroup">
        <div className="blockLeftSide"><h2>Our Partners</h2></div>
        <div className="blockRightSide">
          <div className="ourPartners flexBox">
            {Partners.map((partner) => (
              <div className="" key={partner.id}>
                <Link to={`/partnerships/${partner.id}`}>
                  {
                    partner.logo !== '' && partner['logo'] !== undefined
                      ? <div className="partnerPhoto"><img src={partner.logo} alt={partner.name} /></div>
                      : <div className="partnerPhoto">{partner.name}</div>
                  }
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div> */}

    </div>
    <Footer />
  </div>
);

export default PartnershipsPreview
