import React from 'react'
import { Footer } from '../components/Footer'
import Helmet from 'react-helmet'

function Expertise({ values, tile = false }) {
  return (
    <div>
      {!tile && <Helmet>
        <title>Cuma Collective – Expertise</title>
        <meta name="description"
              content="As founders ourselves, we also love to dream, to innovate, to build.  Together, we push further. We dig deeper. We ask – and answer - the tough questions."/>
      </Helmet>}
      <div className="container">
      <div className="redBlock frameBlock">
        <div className="pageName"><span>04</span>Expertise</div>
        <h1>Value we create</h1>
        <ol className="ourValuesList">
          {values.map((ourValue) => (
            <li key={ourValue.id}>
              <div>{ourValue.title}</div>
              <p>{ourValue.description}</p>
            </li>
          ))}
        </ol>
      </div>
      <Footer />
    </div>
    </div>
  )
}

export default Expertise;
