
export function getIndex(value, arr, prop) {
  for (var i = 0; i < arr.length; i++) {
    if (arr[i][prop] === value) {
      return i;
    }
  }
  return -1; //to handle the case where the value doesn't exist
}

/**
 * Case insensitive string comparison
 * @param {string} a
 * @param {string} b
 * @returns {boolean}
 */
export function equal(a, b) {
  if (typeof a !== 'string' && typeof b !== 'string') return false
  return a.toLowerCase() === b.toLowerCase()
}
