export const API_BASE_URL = '/api';

export const API_URLS = {
  PRINCIPLES: `${API_BASE_URL}/principles`,
  POSTS: `${API_BASE_URL}/posts`,
  COLLECTIVE: `${API_BASE_URL}/collectives`,
  VALUES: `${API_BASE_URL}/values`,
};

export const GOOGLE_ANALYTICS_TRACKING_ID = 'UA-162310401-1';
