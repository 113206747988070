import React from 'react'
import { FaArrowLeft } from 'react-icons/fa'
import Helmet from 'react-helmet'

import { API_BASE_URL } from '../consts'
import arrownext from '../img/red-arrow-web.svg'


function Bio({ data: bio, onChange, collective }) {
  const bioIndex = collective.findIndex(p => p.id === bio.id)

  let indexNext = bioIndex + 1;
  if (!collective[indexNext]) {
    indexNext = 0
  }

  return <>
    <Helmet>
      <title>{bio.meta_title}</title>
      <meta name="description" content={bio.meta_description}/>
    </Helmet>
    <div className="container">
      <div className="bioPage frameBlock blueGreyBlock">
        <div className="backBtn" onClick={() => {
          onChange && onChange();
        }}><FaArrowLeft /></div>
        <div className="flexBox bioBlock">
          <div className="bioPhoto">
            <img className="bio-img"
              key={bio.slug}
              src={bio.img ? `${API_BASE_URL}${bio.img.url}` : "/img/collective/no-photo.png"}
              alt={bio.name} />
          </div>
          <div className="bioAbout">
            <div className="bioName redLineAfter">{bio.name}</div>
            <div className="bioAboutText" dangerouslySetInnerHTML={{ __html: bio.about }} />
          </div>
        </div>

        <div className="frameBlock blueBlock nextArticle">
          <div className="redText">Next up</div>
          <a href="#/"
            onClick={(ev) => {
              ev.preventDefault();
              ev.stopPropagation();

              onChange(collective[indexNext].slug)
            }}
            className="flexBox"
          >
            <h2>{collective[indexNext].name}</h2>
            <div className="nextArrow"><img src={arrownext} alt="next" /></div>
          </a>
        </div>
      </div>
    </div>
  </>
}

export default Bio
