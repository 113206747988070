import React from 'react'
import ReactDOM from 'react-dom'
import WaveSurfer from 'wavesurfer.js'
import play from '../img/play-button-mobile.svg'
import pause from '../img/pause-button-mobile.svg'


export default class Waveform extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTime: 0,
      duration: null,
      paused: false,
    }
  }

  componentDidMount() {
    this.$el = ReactDOM.findDOMNode(this);
    this.$waveform = this.$el.querySelector('.wave');
    this.$waveduration = this.$el.querySelector('.waveform__duration');
    this.$wavecurrent = this.$el.querySelector('.waveform__counter');

    this.wavesurfer = WaveSurfer.create({
      container: this.$waveform,
      waveColor: '#D9DCFF',
      progressColor: '#FF4949',
      barWidth: 3,
      cursorWidth: 0,
      height: 40,
      barGap: 2,
      barHeight: 1,
      responsive: true
    });

    this.wavesurfer.load(this.props.src);

    this.wavesurfer.on('ready', () => this.setState({ duration: Math.floor(this.wavesurfer.getDuration()) }));
    this.wavesurfer.on('audioprocess', () => this.setState({ currentTime: Math.floor(this.wavesurfer.getCurrentTime()) }));

    this.wavesurfer.on('pause', () => this.setState({ paused: false }));
    this.wavesurfer.on('play', () => this.setState({ paused: true }));
  }

  componentWillUnmount() {
    this.wavesurfer.stop()
  }

  playIt = () => {
    this.wavesurfer.playPause();
  };

  render() {
    const { currentTime } = this.state;
    const { duration } = this.state;
    const { paused } = this.state;

    var formatTime = function (time) {
      return [
        Math.floor((time % 3600) / 60), // minutes
        ('00' + Math.floor(time % 60)).slice(-2) // seconds
      ].join(':');
    };
    return (

      <div className='waveform flexBox'>
        <div className="playAudio" onClick={this.playIt}><img src={paused ? pause : play} alt="play" /></div>
        <div className='wave'>
          <div className='waveform__counter'>{formatTime(currentTime)} / {formatTime(duration)}</div>
        </div>
      </div>
    )
  }
}

Waveform.defaultProps = {
  src: ""
};
