import React from 'react'
import { Switch, Route } from 'react-router-dom'
import CollectivePreview from './CollectivePreview'
import Helmet from 'react-helmet'


const Collective = ({ collective }) => (
  <>
    <Helmet>
      <title>Cuma Collective – The Collective</title>
      <meta name="description" content="We’re a collective of investors, advisors and consumers bound by a common denominator: to help achieve better outcomes. We come from diverse backgrounds, professions and industry."/>
    </Helmet>
    <div className="innerContentBackgroundLoaderTop" />
    <Switch>
      <Route
        path='/collective/:id?'
        render={props => <CollectivePreview {...props} collective={collective} />}
      />
    </Switch>
  </>
);

export default Collective
