import React from 'react';
import { Route, Switch } from "react-router-dom"

import Partner from './Partner'
import PartnershipsPreview from './PartnershipsPreview'
import Helmet from 'react-helmet'


const Partnerships = () => (
  <div>
    <Helmet>
      <title>Cuma Collective – Partnerships</title>
      <meta name="description"
            content="We invest in people, businesses and ideas. We look for collaboration that challenges all of us and partnerships that make sense."/>
    </Helmet>
    <div className="container">
      <Switch>
        <Route exact path='/partnerships/:id' component={Partner} />
        <Route exact path='/partnerships' component={PartnershipsPreview} />
      </Switch>
    </div>
  </div>
);

export default Partnerships
