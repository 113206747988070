import React from 'react'
import { Header } from '../components/Header'
import { Footer } from '../components/Footer'

const NotFound = () => {
	return (
		<div className="container">
		  	<Header />
		  	<div className="frameBlock">
		  		<h1>Page not found.</h1>
		  	</div>
			<Footer />
		</div>
	)
};

export default NotFound
