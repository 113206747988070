import React from 'react'
import logo from '../img/logo.svg'

export class Header extends React.Component {
  render() {
    return (
      <header className="header flexBox">
        <div className="logo"><a href="/"><img src={logo} alt="Cuma Collective"/></a></div>
          <div id="menu" onClick={this.props.onMenuClick}>
            <a href="/menu">
              <span></span>
              <span></span>
              <span></span>
            </a>
          </div>
      </header>
    )
  }
}
export default Header
