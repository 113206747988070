import React from 'react'
import Modal from 'react-modal'
import ReactPlayer from 'react-player'
import { FacebookShareButton, TwitterShareButton } from 'react-share'

import { FaArrowLeft, FaFacebookF, FaTwitter } from 'react-icons/fa'
import { history } from '../App'
import { API_BASE_URL } from '../consts'
import Waveform from './Waveform'
import arrownext from '../img/red-arrow-web.svg'
import playImg from '../img/play-button-web.svg'
import Helmet from 'react-helmet'

Modal.setAppElement('#root')

const CONTENT_TYPES = {
  ARTICLE: 'Article',
  AUDIO: 'Audio',
  VIDEO: 'Video'
}

function Article({ slug, onPageChange, posts }) {
  const articleIndex = posts.findIndex(p => p.slug === slug)
  const article = posts[articleIndex]

  const url = window.location.href

  let indexNext = articleIndex + 1
  if (!posts[indexNext]) {
    indexNext = 0
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      zIndex: '12',
    }
  };

  const [modalIsOpen, setIsOpen] = React.useState(false)
  function openModal() {
    setIsOpen(true)
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed
  }

  function closeModal() {
    setIsOpen(false)
  }

  return (
    <>
      <Helmet>
        <title>{article.meta_title}</title>
        <meta name="description" content={article.meta_description}/>
      </Helmet>
      <div className="container">
        <div className="articlePage">
          <div
            className="backBtn"
            onClick={() => {
              history.replace('/journal')
              window.scrollTo(0, 0)
            }}
          ><FaArrowLeft /></div>

          {article.img !== '' ? <div className="articleImageInner" style={{ backgroundImage: `url(${API_BASE_URL}${article.img.url})` }} /> : null}

          <div className="frameBlock greyBlock articleBlock">
            {article.category === CONTENT_TYPES.VIDEO ? <>
              <div className="videoOpen" onClick={openModal}><img src={playImg} alt="open video" /></div>
              <Modal isOpen={modalIsOpen} onAfterOpen={afterOpenModal} onRequestClose={closeModal} style={customStyles}>
                <div className="closeBtn" onClick={closeModal}>Close</div>
                <div className="videoPlayer">
                  <div className='player-wrapper'>
                    <ReactPlayer className='react-player' url={article.videoURL} width='100%' height='100%' controls={true} />
                  </div>
                </div>
              </Modal>
            </> : null}

            <h1>{article.title}</h1>
            <div className="flexBox">

              <div className="blockRightSide">
                <div className="articleAbout">
                  {article.category === CONTENT_TYPES.AUDIO ? <div className='playerWrap'>
                    <Waveform src={`${API_BASE_URL}${article.audio.url}`} />
                  </div> : null}
                  <div className="articleAboutText" dangerouslySetInnerHTML={{ __html: article.text }} />
                </div>
                <div className="shareButtons">
                  <TwitterShareButton url={url} className="shareTwitter">
                    <FaTwitter />
                  </TwitterShareButton>
                  <FacebookShareButton url={url} className="shareFacebook">
                    <FaFacebookF />
                  </FacebookShareButton>
                </div>
              </div>
            </div>
          </div>

          {posts[indexNext] ? <div className="frameBlock blueBlock nextArticle">
            <div className="redText">Next up</div>
            <a href={posts[indexNext].slug}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onPageChange(posts[indexNext].slug)
              }}
              className="flexBox"
            >
              <h2>{posts[indexNext].title}</h2>
              <div className="nextArrow"><img src={arrownext} alt="next" /></div>
            </a>
          </div> : null}

        </div>
      </div>
    </>
  )
}

export default Article;
