import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Helmet from 'react-helmet'

import JournalPreview from './JournalPreview'


const Journal = ({ posts }) => (
  <>
    <Helmet>
      <title>Cuma Collective – Journal</title>
      <meta name="description" content="The Journal is our platform for insightful and innovative thinking from Michael D’Antonio, Dan Mullen, Steve Simoncic and Megan Fales." />
    </Helmet>
    <div className="innerContentBackgroundLoaderTop" />
    <Switch>
      <Route
        path='/journal/:slug?'
        render={props => <JournalPreview {...props} posts={posts} />}
      />
    </Switch>
  </>
)

export default Journal
