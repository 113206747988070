import React from "react";

export const Footer = () => (
  <footer className="footer">
    <div>
      <h5>Contact Us</h5>
      <p><a href="mailto:info@cumacollective.com">info@cumacollective.com</a></p>
      <p><a href="https://www.linkedin.com/in/michael-d-antonio-36b9821/">LinkedIn</a></p>
      <p><a href="https://www.instagram.com/cumacollective/">Instagram</a></p>
      <p><a href="https://twitter.com/CollectiveCuma">Twitter</a></p>
      {/*<p><a href="tel:+XXX-XXX-XXXX">XXX-XXX-XXXX</a></p>*/}
    </div>
    <div className="locationsGroup">
      <h5>Detroit</h5>
      <div>
        <p>6960 Orchard Lake Rd</p>
        <p>West Bloomfield Township, MI 48322</p>
      </div>
    </div>
    <div className="locationsGroup">
      <h5>Miami</h5>
      <div>
        <p>1201 20th Street</p>
        <p>Miami Beach, FL 33139</p>
      </div>
    </div>
    <div className="copyright">
      <p>© <a href="/collective/Michael-D-Antonio">Michael D’Antonio</a> {new Date().getFullYear()}</p>
    </div>
  </footer>
)
